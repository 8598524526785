import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'home',
    // meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: () => import(/* webpackChunkName: "about" */ '../views/home/index.vue')
  },
  {
    path: '/insight',
    name: 'insight',
    // meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: () => import(/* webpackChunkName: "about" */ '../views/home/insight.vue')
  },
  {
    path: '/signature',
    name: 'signature',
    // meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: () => import(/* webpackChunkName: "about" */ '../components/register/signature.vue')
  },
  {
    path: '/register',
    name: 'register',
    // meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: () => import(/* webpackChunkName: "about" */ '../components/register/index.vue')
  },
  {
    path: '/enroll',
    name: 'enroll',
    // meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: () => import(/* webpackChunkName: "about" */ '../components/register/enroll.vue')
  },
  {
    path: '/purchase',
    name: 'purchase',
    // meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: () => import(/* webpackChunkName: "about" */ '../views/purchase/index.vue')
  },
  {
    path: '/immediately',
    name: 'immediately',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: () => import(/* webpackChunkName: "about" */ '../views/purchase/immediately.vue')
  },

  //--------------个人中心
  {
    path: "/personal",
    name: "personal",
    redirect: "/personal/user",
    component: () => import(/* webpackChunkName: "home" */ "../views/personage/index.vue"),
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    children: [
      // 个人中心
      {
        path: "user",
        name: "user",
        meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
        component: () => import(/* webpackChunkName: "home" */ "../views/personage/center.vue"),
      },
      // 我的钱包
      {
        path: "wallet",
        name: "wallet",
        meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
        component: () => import(/* webpackChunkName: "home" */ "../views/personage/wallet.vue"),
      },
      // 实名认证
      {
        path: "autonym",
        name: "autonym",
        meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
        component: () => import(/* webpackChunkName: "home" */ "../views/personage/autonym.vue"),
      },
      // 我的银行卡
      {
        path: "bank",
        name: "bank",
        meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
        component: () => import(/* webpackChunkName: "home" */ "../views/personage/bank.vue"),
      },
      // 我的邀请码
      {
        path: "invitation",
        name: "invitation",
        meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
        component: () => import(/* webpackChunkName: "home" */ '../views/personage/invitation.vue'),
      },
      // 修改密码
      {
        path: "alter",
        name: "alter",
        meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
        component: () => import(/* webpackChunkName: "home" */ "../views/personage/alter.vue"),
      },
    ],
  },
  {
    path: '/payment',
    name: 'payment',
    meta: { isLogin: true },// 添加该字段，表示进入这个路由是需要登录的
    component: () => import(/* webpackChunkName: "about" */ '../views/purchase/payment.vue')
  },
  //-----------未登录


]

const router = new VueRouter({
  routes
})



// 路由守卫
router.beforeEach((to, from, next) => {
  if (to.matched.some(res => res.meta.isLogin)) {//判断是否需要登录
    if (localStorage['token']) {
      next();
    } else {
      next({
        path: "/register"
      });
    }
  } else {
    next()
  }
})

export default router
