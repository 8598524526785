<template>
  <div class="nav">
    <div class="header">
      <div class="header-left" @click="logoStop">
        <img src="../../assets/logo.png" alt="" />
      </div>
      <div class="header-right">
        <div class="right-top">
          <div
            class="top-list"
            v-for="(item, index) in 2"
            :key="index"
            @click="clientStop(index)"
          >
            {{
              index == 0
                ? "中文"
                : token == "" || token == null
                ? "客户登录"
                : "个人中心"
            }}
          </div>


          <div class="top-list" v-if="token != '' && token != null" @click="outStop"> 
            安全退出
          </div>
        </div>
        <div class="right-bottom">
          <div
            class="bottom-list"
            v-for="(item, index) in 7"
            :key="index"
            :class="{ 'bottom-active': index == activeIndex }"
            @click="activeStop(index)"
          >
            {{
              index == 0
                ? "关于我们"
                : index == 1
                ? "服务对象"
                : index == 2
                ? "业务范围"
                : index == 3
                ? "洞察见解"
                : index == 4
                ? "监管单位"
                : index == 5
                ? "聚焦基金"
                : "联系我们"
            }}
          </div>
        </div>
      </div>

      <!-- <div class="header-right">
        <div
          v-if="token == '' || token == null"
          class="right-no"
          @click="registerStop"
        >
          <img src="../../assets/common/nav-isregister.png" alt="" />
        </div>
        <div v-else class="right-block">
          <div class="block-first">
            <div class="first-img">
              <img
                :src="
                  info == null || info.avatar == null
                    ? require('../../assets/common/nav-isregister.png')
                    : 'https://adminafter.evcapital.hk/jeecg-boot/sys/common/static/' +
                      info.avatar
                "
                alt=""
              />
            </div>
            <div class="first-title">
              {{ info == null ? "" : info.username }}
            </div>
          </div>

          <div class="block-second" @click="personalStop">
            个人中心
            <i class="el-icon-arrow-right"></i>
          </div>
          <div class="block-three" @click="outStop">安全退出</div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { postUserinfo } from "../../api/personage";

export default {
  props: ["index"],
  data() {
    return {
      token: localStorage.getItem("token"),
      info: JSON.parse(localStorage.getItem("info")),
      //------
      activeIndex: this.index,
    };
  },
  created() {},
  methods: {
    logoStop() {
      this.$store.commit("homeBtn", 0);
      this.$router.push({
        path: "/",
      });
    },

    // 每次都触发是否判断当前登录
    tokenMet() {
      // console.log("获取token", localStorage.getItem("token"));
      this.token = localStorage.getItem("token");

      this.info = JSON.parse(localStorage.getItem("info"));
    },
    clientStop(index) {
      if (index == 1) {
        if (this.token == "" || this.token == null) {
          this.registerStop();
        } else {
          this.personalStop();
        }
      }
    },

    personalStop() {
      // 获取一次接口请求
      this.$router.push({
        path: "/personal/user",
      });
    },

    outStop() {
      localStorage.clear();
      this.$router.push({
        path: "/",
      });

      this.token = "";
    },
    registerStop() {
      this.$router.push({
        path: "/register",
      });
    },
    activeStop(index) {
      this.activeIndex = index;

      if (this.$route.path == "/") {
        this.$emit("homeAcitveMet", index);
        this.$store.commit("homeBtn", 0);
      } else {
        this.$store.commit("homeBtn", index);

        this.$router.push({
          path: "/",
        });
      }
    },

    //-----首页传入
    homeMet(index) {
      console.log("滚动下标", index);
      this.activeIndex = index;
    },
  },
};
</script>

<style lang="less" scoped>
.nav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  background: rgba(255, 255, 255, 0);

  .header {
    width: 100%;
    height: 120px;
    padding: 0 200px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header-left {
      width: 306px;
      height: 62px;
      cursor: pointer;

      img {
        width: 306px;
        height: 62px;
        cursor: pointer;
      }
    }
    .header-right {
      .right-top {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .top-list {
          width: 90px;
          height: 30px;
          border: 1px solid #1d285b;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #1d285b;
          margin-left: 12px;
          cursor: pointer;
        }

        .top-list:first-child {
          cursor: default;
        }
      }

      .right-bottom {
        margin-top: 15px;
        display: flex;
        align-items: center;

        .bottom-list {
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: bold;
          font-size: 20px;
          color: #1d285b;
          margin-left: 58px;
          cursor: pointer;
        }

        .bottom-active {
          color: #d70e25;
        }
      }
    }

    // .header-right {
    //   .right-no {
    //     width: 50px;
    //     height: 50px;
    //     cursor: pointer;

    //     img {
    //       width: 100%;
    //       height: 100%;
    //     }
    //   }

    //   .right-block {
    //     display: flex;
    //     align-items: center;

    //     .block-first {
    //       display: flex;
    //       align-items: center;

    //       .first-img {
    //         width: 50px;
    //         height: 50px;

    //         img {
    //           width: 100%;
    //           height: 100%;
    //           border-radius: 50%;
    //         }
    //       }

    //       .first-title {
    //         font-family: PingFangSC, PingFang SC;
    //         font-weight: 400;
    //         font-size: 18px;
    //         color: #222222;
    //         margin-left: 11px;
    //       }
    //     }

    //     .block-second {
    //       font-family: PingFangSC, PingFang SC;
    //       font-weight: 400;
    //       font-size: 18px;
    //       color: #ea0014;
    //       margin: 0 40px 0 20px;
    //       cursor: pointer;
    //     }

    //     .block-three {
    //       width: 90px;
    //       height: 30px;
    //       background: #ffffff;
    //       border-radius: 2px;
    //       border: 1px solid #ea0014;
    //       cursor: pointer;
    //       display: flex;
    //       align-items: center;
    //       justify-content: center;
    //       font-family: PingFangSC, PingFang SC;
    //       font-weight: 400;
    //       font-size: 14px;
    //       color: #ea0014;
    //     }
    //   }
    // }
  }
}
</style>