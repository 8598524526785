import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    orderObj: {},
    homeActive: 0
    
  },
  getters: {
  },
  mutations: {
    // 接收页面刷新的时候获取路径参数进行放入vuex管理
    paymentBtn(state, item) {
      state.orderObj = item
     
    },
     // 接收页面刷新的时候获取路径参数进行放入vuex管理
     homeBtn(state, index) {
      state.homeActive = index
    },
    InterfaceerrorBtn(state, id) {
      console.log('vuex缺少', id)
    }
  },
  actions: {
  },
  modules: {
  }
})
