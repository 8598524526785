import { get, post, put, deletefn } from "../api/request";

// 个人信息
export function postUserinfo(params) {
    return post(`/jeecg-boot/front/user/info`, params)
}

// 头像上传
export function postUserinfoAvatar(params) {
    return post(`/jeecg-boot/front/user/avatar`, params)
}





// 持有资产图


// 持有基金
export function getUserHomeOrderList(params) {
    return get(`/jeecg-boot/front/userHomeOrder/list`, params)
}

//------实名认证
// 查询实名认证信息
export function getUserAuthenticationInfo(params) {
    return get(`/jeecg-boot/front/authentication/authenticationInfo`, params)
}

// 提交实名认证
export function postUserAuthenticationInfoAdd(params) {
    return post(`/jeecg-boot/front/authentication/add`, params)
}


// 我的银行卡
// 查询我的银行卡
export function getUserFindBankByUserhomeId(params) {
    return get(`/jeecg-boot/front/bank/findBankByUserhomeId`, params)
}

// 查询绑定支持银行信息
export function getUserbankList(params) {
    return get(`/jeecg-boot/front/bank/bankList`, params)
}

// 绑定银行卡
export function postUserBankbind(params) {
    return post(`/jeecg-boot/front/bank/bind`, params)
}

// 解绑银行卡
export function postUserBankunbind(params) {
    return post(`/jeecg-boot/front/bank/unbind`, params)
}

// 查询网银支持银行信息 
export function getUserbankBank(params) {
    return get(`/jeecg-boot/front/bank/getBank`, params)
}


//  用户基金订单表-分页列表查询
export function getuserHomeOrderwallet(params) {
    return get(`/jeecg-boot/front/userHomeOrder/list`, params)
}

// 钱包余额提款
export function postuserHomeOrderwithraw(params) {
    return post(`/jeecg-boot/front/userHomeOrder/withraw`, params)
}


// 申购汇率byid
export function getuserHomeOrderwalletRate(params) {
    return get(`/jeecg-boot/front/userHomeOrder/queryFundSubscriptionRate`, params)
}