import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

//-----组件配置项
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);


import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);

import vueEsign from 'vue-esign'
Vue.use(vueEsign)

//-----网络配置项
import axios from 'axios' // 网络请求项

import qs from 'qs';
Vue.prototype.$qs = qs;
Vue.prototype.$axios = axios;
Vue.prototype.$http = axios

//----动态配置项
import 'animate.css' // 动画项
import VueAwesomeSwiper from 'vue-awesome-swiper' // 轮播图
Vue.use(VueAwesomeSwiper)

//----界面适配配置项
import '@/utils/rem.js'
 
//------------ 统计
import * as echarts from 'echarts' //引入echarts
Vue.prototype.$echarts = echarts //引入组件

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
